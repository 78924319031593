body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #e5e5e5
}
body::-webkit-scrollbar {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(./assets/fonts/Roboto-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "RobotoRegular";
  src: local("RobotoRegular"), url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
}
.rotate {
  animation: rotation 2s infinite linear;
  margin-top: 300px;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

